import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useAlert } from "react-alert";

import { Button } from "@material-ui/core";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: "white",
      "&:hover": {
        background: "#600101",
      },
    },
  })
);

export default function CopyLinkButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const alert = useAlert();
  const roomKey = localStorage.getItem("userRoomSid");
  const roomLinkOpen = "https://www.talbotcenter.com/room/" + roomKey + "";
  if (roomKey === null) {
    return <></>;
  } else {
    return (
      <Button
        onClick={() => {
          navigator.clipboard.writeText(roomLinkOpen);

          alert.show("Copied to clipboad", {
            timeout: 2000, // custom timeout just for this one alert
            type: "success",
          });
        }}
        className="whitespace-nowrap"
        variant="contained"
        color="secondary"
        type="button"
      >
        Copy Invite Link
      </Button>
    );
  }
}
