import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  FormEvent,
} from "react";
import { useAppState } from "../../state";
import { useHistory } from "react-router-dom";
import UserMenu from "../IntroContainer/UserMenu/UserMenu";
import Button from "@material-ui/core/Button";
import "../../App.css";

import {
  FormControl,
  Theme,
  Paper,
  Table,
  Grid,
  TextField,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import TokenContextProvider from "../../TokenContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const useStyles = makeStyles((theme: Theme) => ({
  butttonParent: {
    flexDirection: "row",
    columnGap: "15px",
  },
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "auto",
    flexDirection: "column",
  },

  phonInput: {
    // width: '100%',
    // border: '1px solid #000',
  },

  container: {
    position: "relative",
    width: "100%",
    flex: "1",
  },
  innerContainer: {
    display: "flex",
    height: "auto",
    borderRadius: "0",
    // boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    // overflow: 'hidden',
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px",
    },
  },
  swooshContainer: {
    background: "white",
    width: "100%",
    padding: "1em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em",
    },
  },

  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em",
    },
  },
  textFieldContainer: {
    width: "100%",
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      background: "white",
      boxShadow: "none",
    },
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em",
    },
  },
  gutterBottom: {
    marginBottom: "1em",
  },
  passcodeContainer: {
    minHeight: "120px",
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const columns: any = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "right",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right",
  },
];

const rows: any = [];

export default function AddUser() {
  const history = useHistory();
  const classes = useStyles();
  const { user, isAuthReady } = useAppState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [conversationList, setConversationList] = useState<any>(null);
  const [formElements, setFormElements] = useState<{
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  }>({
    firstName: "",
    lastName: "",
    phone: "+1",
    email: "",
  });

  useEffect(() => {}, [formElements]);
  const { authToken } = useContext(TokenContextProvider);
  const token = localStorage.getItem("token");
  const addUserAction = useCallback(
    (data) => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/conversation`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`,
            },
            body: JSON.stringify(data),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.code == 200) {
              history.push("/conversation/dashboard");
            }
            //props.startLoading(false);
            setConversationList(data);
          });
      }
    },
    [page, rowsPerPage, authToken]
  );

  /**
   * Call function to handle page navigation
   * @param {*} event
   * @param {*} value
   */
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };
  const isValidEmail = (email: any) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendData = {
      firstName: formElements.firstName,
      lastName: formElements.lastName,
      participant_number: formElements.phone,
      email: formElements.email,
    };
    addUserAction(sendData);
  };

  /**
   * Call function to handle page size
   * @param {*} event
   * @param {*} value
   */
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const roomLink = "/myroom/";
  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(userName, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/' });
  //     })
  //     .catch(err => setAuthError(err));
  // };

  if (!isAuthReady) {
    return null;
  }

  return (
    <>
      <div className={classes.background}>
        <UserMenu />
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <div className={classes.swooshContainer}>
              <div style={{ textAlign: "right" }}></div>
              <Typography variant="h5" className={classes.gutterBottom}>
                Add New Patient Record
              </Typography>

              <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                  <div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-user-name">
                      First Name
                    </InputLabel>
                    <TextField
                      id="input-user-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={(event) => {
                        setFormElements({
                          phone: formElements.phone,
                          firstName: event.target.value,
                          lastName: formElements.lastName,
                          email: formElements.email,
                        });
                      }}
                      value={formElements.firstName}
                    />
                  </div>
                  <div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-room-name">
                      Last Name
                    </InputLabel>
                    <TextField
                      autoCapitalize="false"
                      id="input-room-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={(event) => {
                        setFormElements({
                          phone: formElements.phone,
                          firstName: formElements.firstName,
                          lastName: event.target.value,
                          email: formElements.email,
                        });
                      }}
                      value={formElements.lastName}
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-user-name">
                      Email
                    </InputLabel>
                    <TextField
                      id="input-user-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={(event) => {
                        setFormElements({
                          phone: formElements.phone,
                          firstName: formElements.firstName,
                          lastName: formElements.lastName,
                          email: event.target.value,
                        });
                      }}
                      value={formElements.email}
                    />
                  </div>
                  <div className={classes.textFieldContainer}>
                    <InputLabel shrink htmlFor="input-user-name">
                      Phone
                    </InputLabel>
                    <PhoneInput
                      className={classes.phonInput}
                      fullWidth
                      // disabled={disabled}
                      value={formElements.phone}
                      onChange={(eleme: any) => {
                        setFormElements({
                          phone: eleme,
                          firstName: formElements.firstName,
                          lastName: formElements.lastName,
                          email: formElements.email,
                        });
                      }}
                      // placeholder={placeholder}
                    />
                  </div>
                </div>
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={
                      formElements.firstName == "" ||
                      formElements.lastName == "" ||
                      // formElements.email == '' ||
                      // !isValidEmail(formElements.email) ||
                      formElements.phone == undefined ||
                      formElements.phone.length < 10 ||
                      formElements.phone == "0"
                    }
                    className={classes.continueButton}
                  >
                    Add
                  </Button>
                </Grid>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
