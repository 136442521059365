import React, { useContext, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Theme,
  Hidden,
  Button,
  InputLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { Steps } from "../PreJoinScreens";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { useAppState } from "../../../state";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useParams } from "react-router-dom";
import UniqueNameContextProvider from "../../../UniqueNameContext";
import TokenContextProvider from "../../../TokenContext";

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  marginTop: {
    marginTop: "1em",
  },
  deviceButton: {
    width: "100%",
    border: "2px solid #aaa",
    margin: "1em 0",
  },
  localPreviewContainer: {
    paddingRight: "2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2.5em",
    },
  },
  joinButtons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
      "& button": {
        margin: "0.5em 0",
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1.5em 0 1em",
    },
  },
  mobileButton: {
    padding: "0.8em 0",
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  mediaError?: any;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
  mediaError,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { URLRoomKey } = useParams<{ URLRoomKey?: string }>();
  const { URLRoomToken } = useParams<{ URLRoomToken?: string }>();
  const { mainServerUserId } = useParams<{ mainServerUserId?: string }>();
  const { roomGroupId } = useParams<{ roomGroupId?: string }>();
  const { clientFriendlyName } = useParams<{ clientFriendlyName?: string }>();
  const { updateUniqueNameC } = useContext(UniqueNameContextProvider);
  const [isUserRoom, setIsUserRoom] = useState("");
  const { updateAuthToken, authToken } = useContext(TokenContextProvider);
  const joiningMessage =
    authToken == "" && (URLRoomToken == "" || URLRoomKey !== undefined)
      ? "Please wait as the provider should be joining this call shortly. Thank you."
      : "Please Wait joining now";

  const handleJoin = () => {
    if (URLRoomToken != "undefined") {
      const saveUrlRoomtoken: any = URLRoomToken;
      localStorage.setItem("URLRoomToken", saveUrlRoomtoken);
    }
    const userFriendlyNameToPass =
      clientFriendlyName === undefined || clientFriendlyName == ""
        ? isUserRoom
        : clientFriendlyName;
    getToken(
      URLRoomKey || "",
      URLRoomToken || "",
      mainServerUserId || "",
      roomGroupId || "",
      userFriendlyNameToPass
    ).then(({ token, uniqueName, conversationUniqueName }) => {
      videoConnect(token, URLRoomToken || "");
      updateUniqueNameC(conversationUniqueName || "");
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" &&
        chatConnect(token);
    });
  };
  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: "100%" }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography
            variant="body2"
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              textAlign: "center",
            }}
          >
            {joiningMessage}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      {/* <Typography variant="h5" className={classes.gutterBottom}>
        Join {roomName}
      </Typography> */}
      {authToken == "" ? (
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{
            marginBottom: 10,
            display: clientFriendlyName === undefined ? "" : "none",
          }}
        >
          <div>
            <InputLabel shrink htmlFor="input-room-name">
              Your Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={
                clientFriendlyName === "undefined"
                  ? isUserRoom
                  : clientFriendlyName
              }
              onChange={(event) => setIsUserRoom(event.target.value)}
            />
          </div>
        </Grid>
      ) : null}

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                className={classes.mobileButton}
                disabled={disableButtons}
              />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        {isUserRoom !== "" ||
        authToken != "" ||
        clientFriendlyName !== undefined ? (
          <>
            <Grid item md={5} sm={12} xs={12}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                style={{ height: "100%" }}
              >
                <div>
                  <Hidden smDown>
                    <ToggleAudioButton
                      className={classes.deviceButton}
                      disabled={disableButtons}
                    />
                    {/* <ToggleVideoButton
                      className={classes.deviceButton}
                      disabled={disableButtons}
                    /> */}
                  </Hidden>
                </div>
                <div className={classes.joinButtons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setStep(Steps.roomNameStep)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    data-cy-join-now
                    onClick={handleJoin}
                    disabled={disableButtons}
                  >
                    Join Now
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
}
