import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAppState } from "../../../state";
import UserAvatar from "./UserAvatar/UserAvatar";
import BellIcon from "../../../icons/BellIcon";
import Menu from "@material-ui/core/Menu";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { SyncClient } from "twilio-sync";
import TokenContextProvider from "../../../TokenContext";

// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  userContainer: {
    position: "relative",
    top: 0,
    background: "#fff",
    right: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    width: "100%",
    padding: "1em 4em",
    justifyContent: "left",
    borderBottom: "2px solid #f50257",
    fontWeight: "bold",
    fontSize: "17px",
    marginBottom: "0",
  },
  disapproveBtn: {
    background: "red",
    color: "#fff",
  },
  bellContainer: {
    cursor: "pointer",
  },
  requestDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 0",
  },
  dailogTitle: { color: "green", textAlign: "center" },
  approveBtn: {
    background: "green",
    color: "#fff",
  },
  userButton: {
    color: "#000",
  },
  myRoom: {},
  logoutLink: {
    color: "#000",
    cursor: "pointer",
    padding: "10px 20px",
  },
});

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { user, signOut } = useAppState();
  const { localTracks } = useVideoContext();
  let syncClient: any;

  const [open, setOpen] = React.useState(false);
  const [syncObject, setSyncObject] = useState<any>(null);
  const [newRequest, setNewRequest] = useState<any>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const roomName: any =
    localStorage?.getItem("roomName") != ""
      ? localStorage?.getItem("roomName")
      : "test";
  const rejectInvitations = useCallback(
    (index: any, rowIndex: any) => {
      setOpen(false);
      // syncObject.list(roomName).then(function(list: any) {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 2;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
          // if (newRequest.length < 1) {
          //   setOpen(false);
          // }
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
    },
    [syncObject, setNewRequest, newRequest]
  );
  const acceptInvitations = useCallback(
    (index: any, rowIndex: any) => {
      // setOpen(false);
      // syncObject.list(roomName).then(function(list: any) {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 1;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
          // if (newRequest.length < 1) {
          //   setOpen(false);
          // }
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
      // });
    },
    [syncObject, newRequest, setNewRequest]
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const [isListSubscribed, setListSubscribed] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { authToken } = useContext(TokenContextProvider);
  const roomLink = "/myroom/";
  const handleSignOut = useCallback(() => {
    localTracks.forEach((track) => track.stop());
    signOut?.();
  }, [localTracks, signOut]);

  const subsList = () => {
    setListSubscribed(true);
    fetch(
      `${process.env.REACT_APP_API_URL}api/sync/token`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${authToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const roomName: any =
          localStorage?.getItem("roomName") != ""
            ? localStorage?.getItem("roomName")
            : "test";
        //props.startLoading(false);

        const syncClient2 = new SyncClient(data.token);

        syncClient2
          .list(roomName)
          .then(function(list) {
            setSyncObject(list);
            const pageHandler = (paginator: any) => {
              paginator.items.forEach((item: any) => {
                if (!item.isLocal && item.data.status == 0) {
                  setNewRequest((oldd: any) => [...oldd, item]);
                  setOpen(true);
                } else {
                  list.remove(item.index);
                }
              });
              return paginator.hasNextPage
                ? paginator.nextPage().then(pageHandler)
                : null;
            };
            list
              .getItems({ from: 0, order: "asc", limit: 10 })
              .then(pageHandler)
              .then(() => {
                // if (newRequest.length > 0) setOpen(true);
              })
              .catch((error) => {
                console.error("List getItems() failed", error);
              });

            list.on("itemAdded", (args) => {
              if (!args.isLocal) {
                // newRequest.push(args.item);
                setNewRequest((oldd: any) => [...oldd, args.item]);
                // setNewRequest([args.item]);
              }
              setOpen(true);
            });
          })
          .catch(function(error) {
            console.log("Unexpected error", error);
          });
      });
  };

  useEffect(() => {
    if (!isListSubscribed) {
      subsList();
    }
    if (newRequest.length < 1) {
      setOpen(false);
    }
    console.log("newRequestnewRequest", newRequest, newRequest.length);
  }, [subsList, setOpen, newRequest]);

  if (process.env.REACT_APP_SET_AUTH === "passcode") {
    return (
      <div className={classes.userContainer}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.dailogTitle}>
            {"!! Video Join Request !!"}
          </DialogTitle>
          <DialogContent style={{ width: 430 }}>
            <DialogContentText id="alert-dialog-slide-description">
              {newRequest && newRequest.length > 0 ? (
                <>
                  <div className={classes.requestDiv}>
                    There is a new request in your room please join your room
                    and check it.
                  </div>
                </>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link href="/myroom" className={classes.logoutLink}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                id="clear"
                // className={classes.continueButton}
              >
                Join My Room
              </Button>
            </Link>
          </DialogActions>
        </Dialog>

        <Link href="/dashboard" className={classes.logoutLink}>
          Dashboard
        </Link>
        <Link onClick={handleSignOut} className={classes.logoutLink}>
          Logout
        </Link>
        <Link href={roomLink}>
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="secondary"
            type="button"
          >
            Go to My room
          </Button>
        </Link>
        <div
          className={classes.bellContainer}
          onClick={(event: any) => {
            setOpen(true);
          }}
        >
          <BellIcon />
        </div>

        {localStorage.getItem("userRole") == "Admin" ? (
          <>
            <div className="align-right-menu">
              <Link href="/providers">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Manage Providers
                </Button>
              </Link>
              <Link href="/rooms">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Manage Rooms
                </Button>
              </Link>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  if (process.env.REACT_APP_SET_AUTH === "firebase") {
    return (
      <div className={classes.userContainer}>
        <UserAvatar user={user} />
        <Button
          onClick={() => setMenuOpen((isOpen) => !isOpen)}
          ref={anchorRef}
          className={classes.userButton}
        >
          {user!.displayName}
          <ExpandMoreIcon />
        </Button>
        <Menu
          open={menuOpen}
          onClose={() => setMenuOpen((isOpen) => !isOpen)}
          anchorEl={anchorRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleSignOut}>
            <Typography variant="body1">Logout</Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return null;
};

export default UserMenu;
