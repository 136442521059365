import React from 'react';

const TokenContext = React.createContext({
    authToken: '', updateAuthToken: (data: any) => { },
});;

export const RoutingProvider = TokenContext.Provider
export const RoutingConsumer = TokenContext.Consumer

export default TokenContext;
