import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const login = () => {
    setAuthError(null);
    signIn?.(userName, password )
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  if (user) {
    history.replace('/dashboard');
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <IntroContainer>
      {process.env.REACT_APP_SET_AUTH === 'firebase' && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Sign in to join a room
          </Typography>
          <Typography variant="body1">Sign in using your Twilio Google Account</Typography>
          <Button variant="contained" className={classes.googleButton} onClick={login} startIcon={<GoogleLogo />}>
            Sign in with Google
          </Button>
        </>
      )}

      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          {authError && (
            <div className={classes.errorMessage}>
              <Typography variant="caption"className={classes.errorMessage} >
                <ErrorOutlineIcon />
                {authError.message}
              </Typography>
            </div>
          )}
          <Grid container justifyContent="space-between">
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                User Name
              </InputLabel>
              <TextField
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)}
                type="text"
                variant="outlined"
                size="small"
              />
            </div>

            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Password
              </InputLabel>
              <TextField
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                type="password"
                variant="outlined"
                size="small"
              />
            </div>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!(password.length && userName.length)}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </form>
      </>
    </IntroContainer>
  );
}
