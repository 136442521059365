import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "5px",
      fontSize: "12px",
      color: "#606B85",
    },
    isLocalParticipantMsg: {
      justifyContent: "center",
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({
  author,
  dateCreated,
  isLocalParticipant,
}: MessageInfoProps) {
  const classes = useStyles();

  return (
    <></>
    // <div
    //   className={clsx(classes.messageInfoContainer, {
    //     [classes.isLocalParticipantMsg]: isLocalParticipant,
    //   })}
    // >
    //   {/* <div>{isLocalParticipant ? `${author} (You)` : author}</div> | */}
    //   <div>{dateCreated}</div>
    // </div>
  );
}
